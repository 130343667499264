













































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import * as SetupFileApi from "@/api/setupFile";
import { PageNum } from "@/tool/interface-index";
import { SetupFile } from "@/tool/_class";
import * as _ from "lodash";

@Component({
  name: "SetupFileListPage",
  components: {
    cardBox,
    pagination
  }
})
export default class extends Vue {
  private query: { keyWords?: string } = {

  };
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10
  };
  private tableData: Array<SetupFile> = [];

  private onSubmit() {
    console.log("submit!");
  }

  private deleteData(fileId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
      .then(() => {
        SetupFileApi.deleteSetupFile(fileId).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.getSetupFileData();
          }
        });
      })
      .catch(() => { });
  }



  private loading: boolean = false;
  private async getSetupFileData() {
    try {
      this.loading = true;
      const res = await SetupFileApi.getSetupFileList(_.merge({
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage
      }, this.query));
      this.tableData.splice(0, this.tableData.length);
      res.data.items.forEach((element: any) => {
        let item: SetupFile = new SetupFile();
        _.merge(item, element);
        this.tableData.push(item);
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }

  }

  querySetupFileClick() {
    this.pageNum.curPage = 1;
    this.getSetupFileData();
  }


  private upDataPage() {
    this.getSetupFileData();
  }
  private setSetupFile(fileId: string, type: string) {
    this.$router.push({
      path: "setupFileEditor",
      query: {
        fileId: fileId,
        type: type
      }
    });
  }
  activated() {
    this.getSetupFileData();
  }
  mounted() {

  }

}
